import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_prod_client_i1HKhqBLI4 from "/app/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_eTVJQYlCmx from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugins_i18n_697dcd7f_wJeaFxKXxj from "/app/.nuxt/plugins.i18n.697dcd7f.js";
import axios_r6pSIFccC0 from "/app/node_modules/@shopcircuit/designsystem-vue3/src/plugins/axios.js";
import calendar_r1SK5IA9gP from "/app/node_modules/@shopcircuit/designsystem-vue3/src/plugins/calendar.js";
import composition_sLxaNGmlSL from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import apollo_IRWTEOPleP from "/app/plugins/apollo.js";
import clickOutside_C8jIyDRU4O from "/app/plugins/clickOutside.js";
import mock_service_worker_YgTBG2SRM0 from "/app/plugins/mock-service-worker.js";
import plausible_client_QgwkVYmaxk from "/app/plugins/plausible.client.ts";
import sanitize_html_WXwQQJ9dqO from "/app/plugins/sanitize-html.js";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import unleash_client_client_jDVwq6CkPW from "/app/plugins/unleash-client.client.js";
import vue_gtag_client_fbnXi0Aqdd from "/app/plugins/vue-gtag.client.js";
import vue_gtm_2A2clij21h from "/app/plugins/vue-gtm.js";
import vue_hotjar_Y14dnPlM6X from "/app/plugins/vue-hotjar.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_prod_client_i1HKhqBLI4,
  plugin_eTVJQYlCmx,
  plugins_i18n_697dcd7f_wJeaFxKXxj,
  axios_r6pSIFccC0,
  calendar_r1SK5IA9gP,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  apollo_IRWTEOPleP,
  clickOutside_C8jIyDRU4O,
  mock_service_worker_YgTBG2SRM0,
  plausible_client_QgwkVYmaxk,
  sanitize_html_WXwQQJ9dqO,
  sentry_client_GoGQuZo4Et,
  unleash_client_client_jDVwq6CkPW,
  vue_gtag_client_fbnXi0Aqdd,
  vue_gtm_2A2clij21h,
  vue_hotjar_Y14dnPlM6X
]