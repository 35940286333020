export default defineNuxtPlugin(() => {
    if (typeof window !== 'undefined') {
      // Add the script tag
      const script = document.createElement('script');
      script.src =
        'https://plausible.helios.pyango.ch/js/script.file-downloads.hash.outbound-links.pageview-props.revenue.tagged-events.js';
      script.defer = true;
      script.setAttribute('data-domain', 'shop-circuit.ch');
      document.head.appendChild(script);
  
      // Set up plausible function
      window.plausible = window.plausible || function () {
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };
    }
  });
  